<template>
    <div class="pageContol listWrap templateList">
        <div class="framePage">
            <div class="framePage-title">
                <span>
                    <em>当前位置：</em>
                    <a href="javascript:;">数据中心</a>
                    <i>></i>
                    <a href="javascript:;" @click="$router.back()">简历推送统计</a>
                    <i>></i>
                    <a href="javascript:;" class="cur-a">简历推送情况</a>
                </span>
            </div>
            <div class="framePage-body">
                <div class="operationControl">
                    <div class="searchbox">
                        <div title="岗位名称" class="searchboxItem ci-full">
                            <span class="itemLabel">岗位名称:</span>
                            <el-input
                                v-model="searchForm.positionName"
                                size="small"
                                placeholder="请输入岗位名称"
                                clearable
                            />
                        </div>
                        <div class="df" style="padding-right: 45px">
                            <el-button type="primary" class="bgc-bv" round @click="getResumeStatistics(1)">查询</el-button>
                        </div>
                    </div>
                </div>
                <div class="framePage-scroll">
                    <div class="ovy-a">
                        <el-table
                            :height="tableHeight"
                            size="small"
                            :data="listData"
                            tooltip-effect="dark"
                            :header-cell-style="tableHeader"
                            stripe
                            >
                            <el-table-column
                                label="序号"
                                align="center"
                                type="index"
                                :index="indexMethod"
                                fixed
                            />
                            <el-table-column
                                label="岗位名称"
                                align="center"
                                prop="positionName"
                            />
                            <el-table-column
                                label="姓名"
                                align="center"
                                prop="userName"
                            />
                            <el-table-column
                                label="推送时间"
                                align="center"
                                prop="pushTime"
                            />
                            <el-table-column
                                label="推送人"
                                align="center"
                                prop="pushPerson"
                            />
                            <el-table-column
                                label="操作"
                                fixed="right"
                                width="200px"
                            >
                                <template v-slot="{row}" class="flexcc">
                                    <el-button
                                        type="text"
                                        size="small"
                                        style="padding: 0 5px"
                                        @click="viewState(row)"
                                        >查看简历状态</el-button
                                    >
                                    <el-button
                                        type="text"
                                        size="small"
                                        style="padding: 0 5px"
                                        @click="viewRecord(row)"
                                        >查看沟通记录</el-button
                                    >
                                </template>
                            </el-table-column>
                            <Empty slot="empty" />
                        </el-table>
                    </div>
                </div>
                <PageNum
                    :apiData="pagination"
                    @sizeChange="sizeChange"
                    @getData="currentChange"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import List from "@/mixins/List";
    import Empty from "@/components/Empty.vue";
    import PageNum from "@/components/PageNum.vue";
    export default {
        name: "ResumePushDetail",
        mixins:[List],
        components:{
            Empty,
            PageNum
        },
        data() {
            return {
                // 查询表单的数据
                searchForm:{
                    positionName:"",//岗位名称
                },
                // 分页器数据
                pagination:{
                    total: 0,
                    size: 10,
                    current: 1
                },
                // 表格数据
                listData:[],
                // 企业id
                compId:""
            }
        },
        methods:{
          // 序号
          indexMethod(index) {
            return (this.pagination.current - 1) * this.pagination.size + index + 1;
          },
            // 初始化数据
            initData(){
                this.compId=this.$route.query.compId||"";
                this.getResumeStatistics();
            },
            // 查询数据
            getResumeStatistics(current){
                const {pagination,searchForm,compId}=this;
                // 如果点击的是查询，从第一页开始查询
                if(current){
                    pagination.current=current;
                }
                this.$post("/hr/resume/statistics/position",{
                    compId,
                    positionName:searchForm.positionName,
                    pageNum:pagination.current,
                    pageSize:pagination.size
                }).then(res=>{
                    this.listData=res?.data?.list||[];
                    pagination.total=res?.data?.total||0;
                }).catch(e=>{
                    console.error("获取列表数据出错",e);
                });
            },
            // 查看简历状态
            viewState(row){
                this.$router.push({
                    path:"/web/resumeState",
                    query:{
                        row:JSON.stringify(row)
                    }
                });
            },
            // 查看沟通记录
            viewRecord(row){
                console.log(row);
                this.$router.push({
                    path:"/web/communicationRecord",
                    query:{
                        row:JSON.stringify(row)
                    }
                });
            },
            // 分页器当前页改变
            currentChange(current){
                const {pagination}=this;
                pagination.current=current;
                this.getResumeStatistics();
            },
            // 分页器每页条数改变
            sizeChange(size){
                const {pagination}=this;
                pagination.size=size;
                this.getResumeStatistics();
            },
            // 列表高度
            getTableHeight() {
                this.tableHeight=window.innerHeight-260;
            },
        },
        created(){
            this.initData();
        }
    }
</script>

<style scoped lang="less">

</style>